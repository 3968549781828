import * as Moment from 'moment';
import { IDocumentReviewModel } from '../../core/domain/viewModels/IDocumentReviewModel';
import { EngagementTypeS, SignatureStatus } from '../../core/common/Enums';
var moment = require('moment-timezone');
import { ITaxpayerPaymentSettings, PaymentOption } from '../../core/domain/models/company/Company';
import { IDocumentDisplaySetting } from '../../core/domain/models/IDocumentSettings';
import { IPaymentTransactionModel } from '../../core/domain/models/IPaymentTransactionModel';
import { PaymentState } from '../../core/common/Enums';
import { ICompany } from '../../core/domain/models/company/Company';
import { DocumentGroups, IVoucher, IGroup, VoucherTypes } from '../../core/domain/models/IGroup';
import { IKBAAnswer, IKBAAnswers } from '../../core/domain/models/IKBA';
import { MarsNotifier } from '../Common/Notification/MarsNotifier';
import { initializeAxios } from '../../core/services/dataAccess/DataService.Axios';
import { AxiosResponse } from 'axios';
import { IPendoMetadata, IProfileData } from '../Layout/DefaultLayout';

export function GetFormatedDateTime(date: Date) {
    return Moment.utc(date).local().format('MM/DD/YYYY hh:mm A');
}

export function CountryCodeOptions() {
    var CountryCodeOption = [];
    CountryCodeOption.push({ value: "", label: "Select..." });
    CountryCodeOption.push({ value: "+1", label: "USA/Canada (+1)" });
    CountryCodeOption.push({ value: "+213", label: "Algeria (+213)" });
    CountryCodeOption.push({ value: "+376", label: "Andorra (+376)" });
    CountryCodeOption.push({ value: "+244", label: "Angola (+244)" });
    CountryCodeOption.push({ value: "+1264", label: "Anguilla (+1264)" });
    CountryCodeOption.push({ value: "+1268", label: "Antigua Barbuda (+1268)" });
    CountryCodeOption.push({ value: "+54", label: "Argentina (+54)" });
    CountryCodeOption.push({ value: "+374", label: "Armenia (+374)" });
    CountryCodeOption.push({ value: "+297", label: "Aruba (+297)" });
    CountryCodeOption.push({ value: "+61", label: "Australia (+61)" });
    CountryCodeOption.push({ value: "+43", label: "Austria (+43)" });
    CountryCodeOption.push({ value: "+994", label: "Azerbaijan (+994)" });
    CountryCodeOption.push({ value: "+1242", label: "Bahamas (+1242)" });
    CountryCodeOption.push({ value: "+973", label: "Bahrain (+973)" });
    CountryCodeOption.push({ value: "+880", label: "Bangladesh (+880)" });
    CountryCodeOption.push({ value: "+1246", label: "Barbados (+1246)" });
    CountryCodeOption.push({ value: "+375", label: "Belarus (+375)" });
    CountryCodeOption.push({ value: "+32", label: "Belgium (+32)" });
    CountryCodeOption.push({ value: "+501", label: "Belize (+501)" });
    CountryCodeOption.push({ value: "+229", label: "Benin (+229)" });
    CountryCodeOption.push({ value: "+1441", label: "Bermuda (+1441)" });
    CountryCodeOption.push({ value: "+975", label: "Bhutan (+975)" });
    CountryCodeOption.push({ value: "+591", label: "Bolivia (+591)" });
    CountryCodeOption.push({ value: "+387", label: "Bosnia Herzegovina (+387)" });
    CountryCodeOption.push({ value: "+267", label: "Botswana (+267)" });
    CountryCodeOption.push({ value: "+55", label: "Brazil (+55)" });
    CountryCodeOption.push({ value: "+673", label: "Brunei (+673)" });
    CountryCodeOption.push({ value: "+359", label: "Bulgaria (+359)" });
    CountryCodeOption.push({ value: "+226", label: "Burkina Faso (+226)" });
    CountryCodeOption.push({ value: "+257", label: "Burundi (+257)" });
    CountryCodeOption.push({ value: "+855", label: "Cambodia (+855)" });
    CountryCodeOption.push({ value: "+237", label: "Cameroon (+237)" });
    CountryCodeOption.push({ value: "+238", label: "Cape Verde Islands (+238)" });
    CountryCodeOption.push({ value: "+1345", label: "Cayman Islands (+1345)" });
    CountryCodeOption.push({ value: "+236", label: "Central African Republic (+236)" });
    CountryCodeOption.push({ value: "+56", label: "Chile (+56)" });
    CountryCodeOption.push({ value: "+86", label: "China (+86)" });
    CountryCodeOption.push({ value: "+57", label: "Colombia (+57)" });
    CountryCodeOption.push({ value: "+242", label: "Congo (+242)" });
    CountryCodeOption.push({ value: "+682", label: "Cook Islands (+682)" });
    CountryCodeOption.push({ value: "+506", label: "Costa Rica (+506)" });
    CountryCodeOption.push({ value: "+385", label: "Croatia (+385)" });
    CountryCodeOption.push({ value: "+53", label: "Cuba (+53)" });
    CountryCodeOption.push({ value: "+90392", label: "Cyprus North (+90392)" });
    CountryCodeOption.push({ value: "+357", label: "Cyprus South (+357)" });
    CountryCodeOption.push({ value: "+42", label: "Czech Republic (+42)" });
    CountryCodeOption.push({ value: "+45", label: "Denmark (+45)" });
    CountryCodeOption.push({ value: "+253", label: "Djibouti (+253)" });
    CountryCodeOption.push({ value: "+1809", label: "Dominican Republic (+1809)" });
    CountryCodeOption.push({ value: "+593", label: "Ecuador (+593)" });
    CountryCodeOption.push({ value: "+20", label: "Egypt (+20)" });
    CountryCodeOption.push({ value: "+503", label: "El Salvador (+503)" });
    CountryCodeOption.push({ value: "+240", label: "Equatorial Guinea (+240)" });
    CountryCodeOption.push({ value: "+291", label: "Eritrea (+291)" });
    CountryCodeOption.push({ value: "+372", label: "Estonia (+372)" });
    CountryCodeOption.push({ value: "+251", label: "Ethiopia (+251)" });
    CountryCodeOption.push({ value: "+500", label: "Falkland Islands (+500)" });
    CountryCodeOption.push({ value: "+298", label: "Faroe Islands (+298)" });
    CountryCodeOption.push({ value: "+679", label: "Fiji (+679)" });
    CountryCodeOption.push({ value: "+358", label: "Finland (+358)" });
    CountryCodeOption.push({ value: "+33", label: "France (+33)" });
    CountryCodeOption.push({ value: "+594", label: "French Guiana (+594)" });
    CountryCodeOption.push({ value: "+689", label: "French Polynesia (+689)" });
    CountryCodeOption.push({ value: "+241", label: "Gabon (+241)" });
    CountryCodeOption.push({ value: "+220", label: "Gambia (+220)" });
    CountryCodeOption.push({ value: "+7880", label: "Georgia (+7880)" });
    CountryCodeOption.push({ value: "+49", label: "Germany (+49)" });
    CountryCodeOption.push({ value: "+233", label: "Ghana (+233)" });
    CountryCodeOption.push({ value: "+350", label: "Gibraltar (+350)" });
    CountryCodeOption.push({ value: "+30", label: "Greece (+30)" });
    CountryCodeOption.push({ value: "+299", label: "Greenland (+299)" });
    CountryCodeOption.push({ value: "+1473", label: "Grenada (+1473)" });
    CountryCodeOption.push({ value: "+590", label: "Guadeloupe (+590)" });
    CountryCodeOption.push({ value: "+671", label: "Guam (+671)" });
    CountryCodeOption.push({ value: "+502", label: "Guatemala (+502)" });
    CountryCodeOption.push({ value: "+224", label: "Guinea (+224)" });
    CountryCodeOption.push({ value: "+245", label: "Guinea - Bissau (+245)" });
    CountryCodeOption.push({ value: "+592", label: "Guyana (+592)" });
    CountryCodeOption.push({ value: "+509", label: "Haiti (+509)" });
    CountryCodeOption.push({ value: "+504", label: "Honduras (+504)" });
    CountryCodeOption.push({ value: "+852", label: "Hong Kong (+852)" });
    CountryCodeOption.push({ value: "+36", label: "Hungary (+36)" });
    CountryCodeOption.push({ value: "+354", label: "Iceland (+354)" });
    CountryCodeOption.push({ value: "+91", label: "India (+91)" });
    CountryCodeOption.push({ value: "+62", label: "Indonesia (+62)" });
    CountryCodeOption.push({ value: "+98", label: "Iran (+98)" });
    CountryCodeOption.push({ value: "+964", label: "Iraq (+964)" });
    CountryCodeOption.push({ value: "+353", label: "Ireland (+353)" });
    CountryCodeOption.push({ value: "+972", label: "Israel (+972)" });
    CountryCodeOption.push({ value: "+39", label: "Italy (+39)" });
    CountryCodeOption.push({ value: "+1876", label: "Jamaica (+1876)" });
    CountryCodeOption.push({ value: "+81", label: "Japan (+81)" });
    CountryCodeOption.push({ value: "+962", label: "Jordan (+962)" });
    CountryCodeOption.push({ value: "+254", label: "Kenya (+254)" });
    CountryCodeOption.push({ value: "+686", label: "Kiribati (+686)" });
    CountryCodeOption.push({ value: "+850", label: "Korea North (+850)" });
    CountryCodeOption.push({ value: "+82", label: "Korea South (+82)" });
    CountryCodeOption.push({ value: "+965", label: "Kuwait (+965)" });
    CountryCodeOption.push({ value: "+996", label: "Kyrgyzstan (+996)" });
    CountryCodeOption.push({ value: "+856", label: "Laos (+856)" });
    CountryCodeOption.push({ value: "+371", label: "Latvia (+371)" });
    CountryCodeOption.push({ value: "+961", label: "Lebanon (+961)" });
    CountryCodeOption.push({ value: "+266", label: "Lesotho (+266)" });
    CountryCodeOption.push({ value: "+231", label: "Liberia (+231)" });
    CountryCodeOption.push({ value: "+218", label: "Libya (+218)" });
    CountryCodeOption.push({ value: "+417", label: "Liechtenstein (+417)" });
    CountryCodeOption.push({ value: "+370", label: "Lithuania (+370)" });
    CountryCodeOption.push({ value: "+352", label: "Luxembourg (+352)" });
    CountryCodeOption.push({ value: "+853", label: "Macao (+853)" });
    CountryCodeOption.push({ value: "+389", label: "Macedonia (+389)" });
    CountryCodeOption.push({ value: "+261", label: "Madagascar (+261)" });
    CountryCodeOption.push({ value: "+265", label: "Malawi (+265)" });
    CountryCodeOption.push({ value: "+60", label: "Malaysia (+60)" });
    CountryCodeOption.push({ value: "+960", label: "Maldives (+960)" });
    CountryCodeOption.push({ value: "+223", label: "Mali (+223)" });
    CountryCodeOption.push({ value: "+356", label: "Malta (+356)" });
    CountryCodeOption.push({ value: "+692", label: "Marshall Islands (+692)" });
    CountryCodeOption.push({ value: "+596", label: "Martinique (+596)" });
    CountryCodeOption.push({ value: "+222", label: "Mauritania (+222)" });
    CountryCodeOption.push({ value: "+269", label: "Mayotte (+269)" });
    CountryCodeOption.push({ value: "+52", label: "Mexico (+52)" });
    CountryCodeOption.push({ value: "+691", label: "Micronesia (+691)" });
    CountryCodeOption.push({ value: "+373", label: "Moldova (+373)" });
    CountryCodeOption.push({ value: "+377", label: "Monaco (+377)" });
    CountryCodeOption.push({ value: "+976", label: "Mongolia (+976)" });
    CountryCodeOption.push({ value: "+1664", label: "Montserrat (+1664)" });
    CountryCodeOption.push({ value: "+212", label: "Morocco (+212)" });
    CountryCodeOption.push({ value: "+258", label: "Mozambique (+258)" });
    CountryCodeOption.push({ value: "+95", label: "Myanmar (+95)" });
    CountryCodeOption.push({ value: "+264", label: "Namibia (+264)" });
    CountryCodeOption.push({ value: "+674", label: "Nauru (+674)" });
    CountryCodeOption.push({ value: "+977", label: "Nepal (+977)" });
    CountryCodeOption.push({ value: "+31", label: "Netherlands (+31)" });
    CountryCodeOption.push({ value: "+687", label: "New Caledonia (+687)" });
    CountryCodeOption.push({ value: "+64", label: "New Zealand (+64)" });
    CountryCodeOption.push({ value: "+505", label: "Nicaragua (+505)" });
    CountryCodeOption.push({ value: "+227", label: "Niger (+227)" });
    CountryCodeOption.push({ value: "+234", label: "Nigeria (+234)" });
    CountryCodeOption.push({ value: "+683", label: "Niue (+683)" });
    CountryCodeOption.push({ value: "+672", label: "Norfolk Islands (+672)" });
    CountryCodeOption.push({ value: "+670", label: "Northern Marianas (+670)" });
    CountryCodeOption.push({ value: "+47", label: "Norway (+47)" });
    CountryCodeOption.push({ value: "+968", label: "Oman (+968)" });
    CountryCodeOption.push({ value: "+680", label: "Palau (+680)" });
    CountryCodeOption.push({ value: "+507", label: "Panama (+507)" });
    CountryCodeOption.push({ value: "+675", label: "Papua New Guinea (+675)" });
    CountryCodeOption.push({ value: "+595", label: "Paraguay (+595)" });
    CountryCodeOption.push({ value: "+51", label: "Peru (+51)" });
    CountryCodeOption.push({ value: "+63", label: "Philippines (+63)" });
    CountryCodeOption.push({ value: "+48", label: "Poland (+48)" });
    CountryCodeOption.push({ value: "+351", label: "Portugal (+351)" });
    CountryCodeOption.push({ value: "+1787", label: "Puerto Rico (+1787)" });
    CountryCodeOption.push({ value: "+974", label: "Qatar (+974)" });
    CountryCodeOption.push({ value: "+262", label: "Reunion (+262)" });
    CountryCodeOption.push({ value: "+40", label: "Romania (+40)" });
    CountryCodeOption.push({ value: "+7", label: "Russia (+7)" });
    CountryCodeOption.push({ value: "+250", label: "Rwanda (+250)" });
    CountryCodeOption.push({ value: "+378", label: "San Marino (+378)" });
    CountryCodeOption.push({ value: "+239", label: "Sao Tome Principe (+239)" });
    CountryCodeOption.push({ value: "+966", label: "Saudi Arabia (+966)" });
    CountryCodeOption.push({ value: "+221", label: "Senegal (+221)" });
    CountryCodeOption.push({ value: "+381", label: "Serbia (+381)" });
    CountryCodeOption.push({ value: "+248", label: "Seychelles (+248)" });
    CountryCodeOption.push({ value: "+232", label: "Sierra Leone (+232)" });
    CountryCodeOption.push({ value: "+65", label: "Singapore (+65)" });
    CountryCodeOption.push({ value: "+421", label: "Slovak Republic (+421)" });
    CountryCodeOption.push({ value: "+386", label: "Slovenia (+386)" });
    CountryCodeOption.push({ value: "+677", label: "Solomon Islands (+677)" });
    CountryCodeOption.push({ value: "+252", label: "Somalia (+252)" });
    CountryCodeOption.push({ value: "+27", label: "South Africa (+27)" });
    CountryCodeOption.push({ value: "+34", label: "Spain (+34)" });
    CountryCodeOption.push({ value: "+94", label: "Sri Lanka (+94)" });
    CountryCodeOption.push({ value: "+290", label: "St. Helena (+290)" });
    CountryCodeOption.push({ value: "+1869", label: "St. Kitts (+1869)" });
    CountryCodeOption.push({ value: "+1758", label: "St. Lucia (+1758)" });
    CountryCodeOption.push({ value: "+249", label: "Sudan (+249)" });
    CountryCodeOption.push({ value: "+597", label: "Suriname (+597)" });
    CountryCodeOption.push({ value: "+268", label: "Swaziland (+268)" });
    CountryCodeOption.push({ value: "+46", label: "Sweden (+46)" });
    CountryCodeOption.push({ value: "+41", label: "Switzerland (+41)" });
    CountryCodeOption.push({ value: "+963", label: "Syria (+963)" });
    CountryCodeOption.push({ value: "+886", label: "Taiwan (+886)" });
    CountryCodeOption.push({ value: "+66", label: "Thailand (+66)" });
    CountryCodeOption.push({ value: "+228", label: "Togo (+228)" });
    CountryCodeOption.push({ value: "+676", label: "Tonga (+676)" });
    CountryCodeOption.push({ value: "+1868", label: "Trinidad Tobago (+1868)" });
    CountryCodeOption.push({ value: "+216", label: "Tunisia (+216)" });
    CountryCodeOption.push({ value: "+90", label: "Turkey (+90)" });
    CountryCodeOption.push({ value: "+993", label: "Turkmenistan (+993)" });
    CountryCodeOption.push({ value: "+1649", label: "Turks Caicos Islands (+1649)" });
    CountryCodeOption.push({ value: "+688", label: "Tuvalu (+688)" });
    CountryCodeOption.push({ value: "+256", label: "Uganda (+256)" });
    CountryCodeOption.push({ value: "+44", label: "UK (+44)" });
    CountryCodeOption.push({ value: "+380", label: "Ukraine (+380)" });
    CountryCodeOption.push({ value: "+971", label: "United Arab Emirates (+971)" });
    CountryCodeOption.push({ value: "+598", label: "Uruguay (+598)" });
    CountryCodeOption.push({ value: "+678", label: "Vanuatu (+678)" });
    CountryCodeOption.push({ value: "+379", label: "Vatican City (+379)" });
    CountryCodeOption.push({ value: "+58", label: "Venezuela (+58)" });
    CountryCodeOption.push({ value: "+84", label: "Virgin Islands" });
    CountryCodeOption.push({ value: "+681", label: "Wallis Futuna (+681)" });
    CountryCodeOption.push({ value: "+969", label: "Yemen North (+969)" });
    CountryCodeOption.push({ value: "+967", label: "Yemen South (+967)" });
    CountryCodeOption.push({ value: "+260", label: "Zambia (+260)" });
    CountryCodeOption.push({ value: "+263", label: "Zimbabwe (+263)" });
    return CountryCodeOption;
}

export function GetCountryCodeWithoutCountryName() {
    var CountryCodeOption = [];

    CountryCodeOption.push({ value: "+1", label: "+1" });
    CountryCodeOption.push({ value: "+213", label: "+213" });
    CountryCodeOption.push({ value: "+376", label: "+376" });
    CountryCodeOption.push({ value: "+244", label: "+244" });
    CountryCodeOption.push({ value: "+1264", label: "+1264" });
    CountryCodeOption.push({ value: "+1268", label: "+1268" });
    CountryCodeOption.push({ value: "+54", label: "+54" });
    CountryCodeOption.push({ value: "+374", label: "+374" });
    CountryCodeOption.push({ value: "+297", label: "+297" });
    CountryCodeOption.push({ value: "+61", label: "+61" });
    CountryCodeOption.push({ value: "+43", label: "+43" });
    CountryCodeOption.push({ value: "+994", label: "+994" });
    CountryCodeOption.push({ value: "+1242", label: "+1242" });
    CountryCodeOption.push({ value: "+973", label: "+973" });
    CountryCodeOption.push({ value: "+880", label: "+880" });
    CountryCodeOption.push({ value: "+1246", label: "+1246" });
    CountryCodeOption.push({ value: "+375", label: "+375" });
    CountryCodeOption.push({ value: "+32", label: "+32" });
    CountryCodeOption.push({ value: "+501", label: "+501" });
    CountryCodeOption.push({ value: "+229", label: "+229" });
    CountryCodeOption.push({ value: "+1441", label: "+1441" });
    CountryCodeOption.push({ value: "+975", label: "+975" });
    CountryCodeOption.push({ value: "+591", label: "+591" });
    CountryCodeOption.push({ value: "+387", label: "+387" });
    CountryCodeOption.push({ value: "+267", label: "+267" });
    CountryCodeOption.push({ value: "+55", label: "+55" });
    CountryCodeOption.push({ value: "+673", label: "+673" });
    CountryCodeOption.push({ value: "+359", label: "+359" });
    CountryCodeOption.push({ value: "+226", label: "+226" });
    CountryCodeOption.push({ value: "+257", label: "+257" });
    CountryCodeOption.push({ value: "+855", label: "+855" });
    CountryCodeOption.push({ value: "+237", label: "+237" });
    CountryCodeOption.push({ value: "+238", label: "+238" });
    CountryCodeOption.push({ value: "+1345", label: "+1345" });
    CountryCodeOption.push({ value: "+236", label: "+236" });
    CountryCodeOption.push({ value: "+56", label: "+56" });
    CountryCodeOption.push({ value: "+86", label: "+86" });
    CountryCodeOption.push({ value: "+57", label: "+57" });
    CountryCodeOption.push({ value: "+242", label: "+242" });
    CountryCodeOption.push({ value: "+682", label: "+682" });
    CountryCodeOption.push({ value: "+506", label: "+506" });
    CountryCodeOption.push({ value: "+385", label: "+385" });
    CountryCodeOption.push({ value: "+53", label: "+53" });
    CountryCodeOption.push({ value: "+90392", label: "+90392" });
    CountryCodeOption.push({ value: "+357", label: "+357" });
    CountryCodeOption.push({ value: "+42", label: "+42" });
    CountryCodeOption.push({ value: "+45", label: "+45" });
    CountryCodeOption.push({ value: "+253", label: "+253" });
    CountryCodeOption.push({ value: "+1809", label: "+1809" });
    CountryCodeOption.push({ value: "+593", label: "+593" });
    CountryCodeOption.push({ value: "+20", label: "+20" });
    CountryCodeOption.push({ value: "+503", label: "+503" });
    CountryCodeOption.push({ value: "+240", label: "+240" });
    CountryCodeOption.push({ value: "+291", label: "+291" });
    CountryCodeOption.push({ value: "+372", label: "+372" });
    CountryCodeOption.push({ value: "+251", label: "+251" });
    CountryCodeOption.push({ value: "+500", label: "+500" });
    CountryCodeOption.push({ value: "+298", label: "+298" });
    CountryCodeOption.push({ value: "+679", label: "+679" });
    CountryCodeOption.push({ value: "+358", label: "+358" });
    CountryCodeOption.push({ value: "+33", label: "+33" });
    CountryCodeOption.push({ value: "+594", label: "+594" });
    CountryCodeOption.push({ value: "+689", label: "+689" });
    CountryCodeOption.push({ value: "+241", label: "+241" });
    CountryCodeOption.push({ value: "+220", label: "+220" });
    CountryCodeOption.push({ value: "+7880", label: "+7880" });
    CountryCodeOption.push({ value: "+49", label: "+49" });
    CountryCodeOption.push({ value: "+233", label: "+233" });
    CountryCodeOption.push({ value: "+350", label: "+350" });
    CountryCodeOption.push({ value: "+30", label: "+30" });
    CountryCodeOption.push({ value: "+299", label: "+299" });
    CountryCodeOption.push({ value: "+1473", label: "+1473" });
    CountryCodeOption.push({ value: "+590", label: "+590" });
    CountryCodeOption.push({ value: "+671", label: "+671" });
    CountryCodeOption.push({ value: "+502", label: "+502" });
    CountryCodeOption.push({ value: "+224", label: "+224" });
    CountryCodeOption.push({ value: "+245", label: "+245" });
    CountryCodeOption.push({ value: "+592", label: "+592" });
    CountryCodeOption.push({ value: "+509", label: "+509" });
    CountryCodeOption.push({ value: "+504", label: "+504" });
    CountryCodeOption.push({ value: "+852", label: "+852" });
    CountryCodeOption.push({ value: "+36", label: "+36" });
    CountryCodeOption.push({ value: "+354", label: "+354" });
    CountryCodeOption.push({ value: "+91", label: "+91" });
    CountryCodeOption.push({ value: "+62", label: "+62" });
    CountryCodeOption.push({ value: "+98", label: "+98" });
    CountryCodeOption.push({ value: "+964", label: "+964" });
    CountryCodeOption.push({ value: "+353", label: "+353" });
    CountryCodeOption.push({ value: "+972", label: "+972" });
    CountryCodeOption.push({ value: "+39", label: "+39" });
    CountryCodeOption.push({ value: "+1876", label: "+1876" });
    CountryCodeOption.push({ value: "+81", label: "+81" });
    CountryCodeOption.push({ value: "+962", label: "+962" });
    CountryCodeOption.push({ value: "+254", label: "+254" });
    CountryCodeOption.push({ value: "+686", label: "+686" });
    CountryCodeOption.push({ value: "+850", label: "+850" });
    CountryCodeOption.push({ value: "+82", label: "+82" });
    CountryCodeOption.push({ value: "+965", label: "+965" });
    CountryCodeOption.push({ value: "+996", label: "+996" });
    CountryCodeOption.push({ value: "+856", label: "+856" });
    CountryCodeOption.push({ value: "+371", label: "+371" });
    CountryCodeOption.push({ value: "+961", label: "+961" });
    CountryCodeOption.push({ value: "+266", label: "+266" });
    CountryCodeOption.push({ value: "+231", label: "+231" });
    CountryCodeOption.push({ value: "+218", label: "+218" });
    CountryCodeOption.push({ value: "+417", label: "+417" });
    CountryCodeOption.push({ value: "+370", label: "+370" });
    CountryCodeOption.push({ value: "+352", label: "+352" });
    CountryCodeOption.push({ value: "+853", label: "+853" });
    CountryCodeOption.push({ value: "+389", label: "+389" });
    CountryCodeOption.push({ value: "+261", label: "+261" });
    CountryCodeOption.push({ value: "+265", label: "+265" });
    CountryCodeOption.push({ value: "+60", label: "+60" });
    CountryCodeOption.push({ value: "+960", label: "+960" });
    CountryCodeOption.push({ value: "+223", label: "+223" });
    CountryCodeOption.push({ value: "+356", label: "+356" });
    CountryCodeOption.push({ value: "+692", label: "+692" });
    CountryCodeOption.push({ value: "+596", label: "+596" });
    CountryCodeOption.push({ value: "+222", label: "+222" });
    CountryCodeOption.push({ value: "+269", label: "+269" });
    CountryCodeOption.push({ value: "+52", label: "+52" });
    CountryCodeOption.push({ value: "+691", label: "+691" });
    CountryCodeOption.push({ value: "+373", label: "+373" });
    CountryCodeOption.push({ value: "+377", label: "+377" });
    CountryCodeOption.push({ value: "+976", label: "+976" });
    CountryCodeOption.push({ value: "+1664", label: "+1664" });
    CountryCodeOption.push({ value: "+212", label: "+212" });
    CountryCodeOption.push({ value: "+258", label: "+258" });
    CountryCodeOption.push({ value: "+95", label: "+95" });
    CountryCodeOption.push({ value: "+264", label: "+264" });
    CountryCodeOption.push({ value: "+674", label: "+674" });
    CountryCodeOption.push({ value: "+977", label: "+977" });
    CountryCodeOption.push({ value: "+31", label: "+31" });
    CountryCodeOption.push({ value: "+687", label: "+687" });
    CountryCodeOption.push({ value: "+64", label: "+64" });
    CountryCodeOption.push({ value: "+505", label: "+505" });
    CountryCodeOption.push({ value: "+227", label: "+227" });
    CountryCodeOption.push({ value: "+234", label: "+234" });
    CountryCodeOption.push({ value: "+683", label: "+683" });
    CountryCodeOption.push({ value: "+672", label: "+672" });
    CountryCodeOption.push({ value: "+670", label: "+670" });
    CountryCodeOption.push({ value: "+47", label: "+47" });
    CountryCodeOption.push({ value: "+968", label: "+968" });
    CountryCodeOption.push({ value: "+680", label: "+680" });
    CountryCodeOption.push({ value: "+507", label: "+507" });
    CountryCodeOption.push({ value: "+675", label: "+675" });
    CountryCodeOption.push({ value: "+595", label: "+595" });
    CountryCodeOption.push({ value: "+51", label: "+51" });
    CountryCodeOption.push({ value: "+63", label: "+63" });
    CountryCodeOption.push({ value: "+48", label: "+48" });
    CountryCodeOption.push({ value: "+351", label: "+351" });
    CountryCodeOption.push({ value: "+1787", label: "+1787" });
    CountryCodeOption.push({ value: "+974", label: "+974" });
    CountryCodeOption.push({ value: "+262", label: "+262" });
    CountryCodeOption.push({ value: "+40", label: "+40" });
    CountryCodeOption.push({ value: "+7", label: "+7" });
    CountryCodeOption.push({ value: "+250", label: "+250" });
    CountryCodeOption.push({ value: "+378", label: "+378" });
    CountryCodeOption.push({ value: "+239", label: "+239" });
    CountryCodeOption.push({ value: "+966", label: "+966" });
    CountryCodeOption.push({ value: "+221", label: "+221" });
    CountryCodeOption.push({ value: "+381", label: "+381" });
    CountryCodeOption.push({ value: "+248", label: "+248" });
    CountryCodeOption.push({ value: "+232", label: "+232" });
    CountryCodeOption.push({ value: "+65", label: "+65" });
    CountryCodeOption.push({ value: "+421", label: "+421" });
    CountryCodeOption.push({ value: "+386", label: "+386" });
    CountryCodeOption.push({ value: "+677", label: "+677" });
    CountryCodeOption.push({ value: "+252", label: "+252" });
    CountryCodeOption.push({ value: "+27", label: "+27" });
    CountryCodeOption.push({ value: "+34", label: "+34" });
    CountryCodeOption.push({ value: "+94", label: "+94" });
    CountryCodeOption.push({ value: "+290", label: "+290" });
    CountryCodeOption.push({ value: "+1869", label: "+1869" });
    CountryCodeOption.push({ value: "+1758", label: "+1758" });
    CountryCodeOption.push({ value: "+249", label: "+249" });
    CountryCodeOption.push({ value: "+597", label: "+597" });
    CountryCodeOption.push({ value: "+268", label: "+268" });
    CountryCodeOption.push({ value: "+46", label: "+46" });
    CountryCodeOption.push({ value: "+41", label: "+41" });
    CountryCodeOption.push({ value: "+963", label: "+963" });
    CountryCodeOption.push({ value: "+886", label: "+886" });
    CountryCodeOption.push({ value: "+66", label: "+66" });
    CountryCodeOption.push({ value: "+228", label: "+228" });
    CountryCodeOption.push({ value: "+676", label: "+676" });
    CountryCodeOption.push({ value: "+1868", label: "+1868" });
    CountryCodeOption.push({ value: "+216", label: "+216" });
    CountryCodeOption.push({ value: "+90", label: "+90" });
    CountryCodeOption.push({ value: "+993", label: "+993" });
    CountryCodeOption.push({ value: "+1649", label: "+1649" });
    CountryCodeOption.push({ value: "+688", label: "+688" });
    CountryCodeOption.push({ value: "+256", label: "+256" });
    CountryCodeOption.push({ value: "+44", label: "+44" });
    CountryCodeOption.push({ value: "+380", label: "+380" });
    CountryCodeOption.push({ value: "+971", label: "+971" });
    CountryCodeOption.push({ value: "+598", label: "+598" });
    CountryCodeOption.push({ value: "+678", label: "+678" });
    CountryCodeOption.push({ value: "+379", label: "+379" });
    CountryCodeOption.push({ value: "+58", label: "+58" });
    CountryCodeOption.push({ value: "+84", label: "+84" });
    CountryCodeOption.push({ value: "+681", label: "+681" });
    CountryCodeOption.push({ value: "+969", label: "+969" });
    CountryCodeOption.push({ value: "+967", label: "+967" });
    CountryCodeOption.push({ value: "+260", label: "+260" });
    CountryCodeOption.push({ value: "+263", label: "+263" });
    return CountryCodeOption;
}

export function EntityTypeOptions() {
    var entityTypeOption = [];
    entityTypeOption.push({ value: '1', label: 'Individual' });
    entityTypeOption.push({ value: '2', label: 'Entity' }); 
    return entityTypeOption;
}

export function k1DownloadOptions() {
    var k1DownloadOptions = [];
    k1DownloadOptions.push({ value: '1', label: 'Download All K-1s' });
    k1DownloadOptions.push({ value: '2', label: 'Download Selected K-1s' }); 
    return k1DownloadOptions;
}

export function k1StatusOptions() {
    var k1StatusOption = [];
    k1StatusOption.push({ value: '1', label: 'Updated' });
    k1StatusOption.push({ value: '2', label: 'Confirmed' });
    k1StatusOption.push({ value: '3', label: 'Pending' });
    return k1StatusOption;
}

export function hideReviewStep(reviewDocuments: IDocumentReviewModel[]): boolean {
    if (reviewDocuments.length > 0) {
        return false;
    }
    return true;
}

export function getLocalDate(): string {
    const d = new Date();
    const localDate = d.getDate().toString().padStart(2, "0") + '-' + (d.getMonth() + 1).toString().padStart(2, "0") + '-' + d.getFullYear() + ' ' + d.getHours().toString().padStart(2, "0") + ':' + d.getMinutes().toString().padStart(2, "0");
    return localDate;
}

export function truncateString(fileName: string, maxLength: number, stringEnd: number) {
    if (fileName.length > maxLength) {
        let truncatedString = fileName.substring(0, stringEnd);
        truncatedString = truncatedString + "...";
        return truncatedString;
    }
    else {
        return fileName;
    }
}

export function isIndividualEngagementType(type: EngagementTypeS): boolean {
    return type === EngagementTypeS.E1040
        || type === EngagementTypeS.E1040NR;
}

export function getUserTimeZone() {
    return encodeURIComponent(moment.tz.guess(true));
}
export function isInvoicePaymentSuccess(paymentData: IPaymentTransactionModel): boolean {
    if (paymentData && paymentData.transactionId && paymentData.status == PaymentState.Success) {
        return true;
    }
    return false;
}

export function isStripeEnabled(companyData: ICompany): boolean {
    return companyData.taxpayerPaymentSettings.payOption === PaymentOption.Stripe;
}
export function isAttachedUrlEnabled(company: ICompany): boolean {
    if (company.taxpayerPaymentSettings.payOption === PaymentOption.AttachedURL && company.onlinePaymentUrl) {
        return true;
    }
    return false;
}
export function isAnyPaySettingsEnabled(company: ICompany): boolean {
    if (isStripeEnabled(company) || isAttachedUrlEnabled(company)) {
        return true;
    }
    return false;
}

export function getCookie(cookiename: string) {
    var cookiestring = document.cookie;
    var cookiearray = cookiestring.split(";");
    for (var i = 0; i < cookiearray.length; ++i) {
        if (cookiearray[i].trim().match("^" + cookiename + "=")) {
            return cookiearray[i].replace(`${cookiename}=`, "").trim();
        }
    }
    return null;
}

export function setCookie(key: string, value: string, days: number = 1) {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + days);
    document.cookie = `${key}=${value}; expires=${expirationDate.toUTCString()}; path=/`;
}

export function showPayNow(company: ICompany, paymentData: IPaymentTransactionModel, invoiceAmount: number): boolean {
    if (company.taxpayerPaymentSettings && paymentData) {

        //check weather Payment is already done irrespective of Payment type OR Invoice amout is <= 0
        if (isInvoicePaymentSuccess(paymentData) || invoiceAmount <= 0) {
            return false;
        }

        //Only enable button when stripe and attached URL settings.
        if (isAnyPaySettingsEnabled(company)) {
            return true;
        }
        else {
            return false;
        }
    }
    else {
        return false;
    }
}

export function buttonNameChange(paymentData: IPaymentTransactionModel,
    invoiceAmount: number, companyData: ICompany,
    documentDisplaySetting: IDocumentDisplaySetting): boolean {    

    if (isInvoicePaymentSuccess(paymentData) || invoiceAmount <= 0) {
        return false;
    }
    if (isStripeEnabled(companyData) && !documentDisplaySetting.priorToReview) {
        return true;
    }
    return false;
}



export function PrepareTaxYear(minTaxYear?: number) {
    if (minTaxYear === undefined) {
        minTaxYear = 2014;
    }
    var years = [];
    for (let i = new Date().getFullYear() - 1; i >= minTaxYear; i--) {
        years.push({
            value: i,
            label: i.toString()
        })
    }
    return years;
}

export function hidePayLater(
    companyData: ICompany,
    displaySetting: IDocumentDisplaySetting,
    paymentData: IPaymentTransactionModel,
    invoiceAmout: number): boolean {

    if (isInvoicePaymentSuccess(paymentData) || invoiceAmout <= 0) {
        return false;
    }
    if (isStripeEnabled(companyData) && displaySetting.priorToReview) {
            return true;
    }
    return false;
}


export const checkVoucherValidation = (taxReturn: any, voucherType: VoucherTypes, noVoucherError:string,callback:()=>void) => {
    const formGroup: IGroup = taxReturn.formGroups.find((x: any) => x.group === DocumentGroups.Vouchers) || {
        group: DocumentGroups.Vouchers,
        forms: []
    };
    let validVouchers = false;
    let vouchers: IVoucher[] = formGroup.forms as IVoucher[];
    for (var i = 0; i < vouchers.length; i++) {
        if (vouchers[i].amount > 0 && vouchers[i].paymentType === voucherType) {
            validVouchers = true;
            break;
        }
    }
    if (!validVouchers) {
        MarsNotifier.Warning(noVoucherError, null);
    }
    else {
        callback();
        
    }
}

export const generateControllerSessionId = (controllerId: string) => {
    return "openedTaxPayerWindow_" + controllerId;
}


export const removeOpenedWindowStatus = (controllerId:string) => {
    

    let storedValue: any = localStorage.getItem(generateControllerSessionId(controllerId));
    if (storedValue !== null) {
        if (storedValue === '0') {
            localStorage.removeItem(generateControllerSessionId(controllerId));
        }
        else {
            let openTaxWindows: number = parseInt(storedValue);
            openTaxWindows -= 1;
            localStorage.setItem(generateControllerSessionId(controllerId), openTaxWindows + "");
        }
    }
    return;
}


export const windowUnloadStep = (controllerId: string,iscontrollerDashboard:boolean) => {

    let storedValue: any = localStorage.getItem(generateControllerSessionId(controllerId));
    if (storedValue !== null) {

        
        if (iscontrollerDashboard || storedValue === '0') {
            localStorage.removeItem(generateControllerSessionId(controllerId));
        }
        else {
            let openTaxWindows: number = parseInt(storedValue);
            openTaxWindows -= 1;
            localStorage.setItem(generateControllerSessionId(controllerId), openTaxWindows + "");
        }

    }



    return;
}

export const openURLInNewWindow = (url: string) => {
    if(url && (url.trim().toLocaleLowerCase().substring(0, 7)==='http://' || url.trim().toLocaleLowerCase().substring(0, 8) ==='https://')){
        window.open(url);
        return;
    }
    url && window.open("https://" + url);
}

export const isDraftReturnReviewed = (signatureStatus: any) => {
    return signatureStatus == SignatureStatus.DraftApproved || signatureStatus ==SignatureStatus.DraftRejected || signatureStatus == SignatureStatus.FinalReturnCopyCreated;
}

export const resizeEventHandler = (event: UIEvent) => {

        if (document.activeElement?.tagName?.toLowerCase() === "input") {
            event.stopImmediatePropagation();
        }
    }

export const encodeChoiceText = (kbaAnswers: IKBAAnswers) => {
    if(kbaAnswers != null && kbaAnswers != undefined){
        if(kbaAnswers.answers != null && kbaAnswers.answers != undefined){
            kbaAnswers.answers.forEach((answer: IKBAAnswer) => {
                answer.choice.choiceText = encodeURIComponent(answer.choice.choiceText);
            });
        }       
    }
}

export const handleKeyDown = (event: any, callback: () => void, key: string = 'Enter') => {
    if (event && event.key === key) {
        event.preventDefault();
        callback();
    }
}

export const injectPendoScript = (
	companyId: number,
	companyName: string,
	userId: string,
	name: string,
	email: string,
    isTestCompany: boolean,
    subscription: string,
    isPendoInjected: boolean,
    setIsPendoInjected: (isPendoInjected: boolean) => void
) => {
	if (!companyId || !companyName || !userId || !name || !email || !subscription || isPendoInjected) {
		return;
	}

    setIsPendoInjected(true);
	const apiKey = process.env.REACT_APP_CLIENT_PENDO_API_KEY;
    const scriptId = 'taxpayer-pendo-script';

    const existingScript = document.getElementById(scriptId);
    if (existingScript) {
        existingScript.remove();
    }

	(function (p: any, e: any, n: any, d: any, o: any) {
		var v: any, w: any, x: any, y: any, z: any;
		o = p[d] = p[d] || {};
		o._q = o._q || [];
		v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
		for (w = 0, x = v.length; w < x; ++w)
			(function (m) {
				o[m] =
					o[m] ||
					function () {
						o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
					};
			})(v[w]);
		y = e.createElement(n);
		y.async = !0;
		y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
        y.id = scriptId;
		z = e.getElementsByTagName(n)[0];
		z.parentNode.insertBefore(y, z);
	})(window, document, 'script', 'pendo', '');

	window.pendo?.initialize({
		visitor: {
			id: `${companyId}-${userId}-${email}`,
			email: email,
			name: name,
		},
		account: {
			accountid: companyId,
			accountname: companyName,
            firmtype: isTestCompany ? 'Internal' : 'Live',
			subscription: subscription,
		},
	});
};

export const getClientBasicInfo = (clientId: string, callback: (data: any) => void) => {
    initializeAxios()
        .get("api/Helper/GetClientBasicInfo/" + clientId)
        .then((response: AxiosResponse<IProfileData>) => {
            callback(response.data);
        })
        .catch((error: any) => console.log(error));
};

export const getPendoMetadata = (clientId: string, callback: (data: IPendoMetadata) => void) => {
    initializeAxios()
        .get("api/Company/pendo-metadata/" + clientId)
        .then((response: AxiosResponse<any>) => {
            callback(response.data?.value);
        })
        .catch((error: any) => console.log(error));
}
